<template>
  <NuxtLinkLocale
    class="logo-component mx-5 my-0 flex self-center md:my-5"
    data-cy="juniqe-logo"
    to="/"
  >
    <svg
      class="aspect-[5/1] w-[70px] md:h-auto md:w-[100px]"
      viewBox="0 0 213.77 40.79"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0,36.86c2.66,2.62,5.87,3.93,9.63,3.93s6.89-1.31,9.63-3.93c2.66-2.7,3.99-5.91,3.99-9.63V.06h-6.87v27.17c0,1.88-.67,3.48-2,4.81-1.29,1.29-2.88,1.94-4.75,1.94s-3.42-.66-4.75-2l-4.87,4.87h0ZM67.38.06h-6.81v23.77c0,2.78-1.02,5.17-3.05,7.16-1.96,2-4.34,2.99-7.16,2.99s-5.22-1-7.22-2.99c-1.96-1.96-2.93-4.34-2.93-7.16V.06h-6.75v23.77c0,4.66,1.64,8.67,4.93,12.03,3.37,3.29,7.36,4.93,11.97,4.93s8.67-1.64,12.03-4.93c3.33-3.33,4.99-7.34,4.99-12.03V.06h0ZM76.07,40.73h6.75v-24.36l27.18,24.42v-.12.06V0h-6.81v24.42L76.07,0v40.73h0ZM121.73,40.79h6.75V.06h-6.75v40.73h0ZM141.63,6.04c-3.99,3.99-5.99,8.79-5.99,14.38s2,10.43,5.99,14.38c3.95,3.99,8.74,5.99,14.38,5.99,4.3,0,8.24-1.25,11.8-3.76l3.76,3.76,4.87-4.81-3.82-3.76c2.54-3.52,3.82-7.45,3.82-11.8,0-5.6-2.02-10.39-6.05-14.38-3.95-3.99-8.75-5.99-14.38-5.99s-10.47,2-14.38,5.99h0ZM165.58,10.86c2.66,2.66,3.99,5.85,3.99,9.57s-1.33,6.93-3.99,9.63c-2.66,2.62-5.85,3.93-9.57,3.93s-6.93-1.31-9.63-3.93c-2.62-2.7-3.93-5.91-3.93-9.63s1.31-6.91,3.93-9.57c2.7-2.66,5.91-3.99,9.63-3.99s6.91,1.33,9.57,3.99h0ZM186.65.06v40.73h27.12v-6.81h-20.37v-10.15h20.37v-6.75h-20.37V6.87h20.37V.06h-27.12Z"
        fill="#1e1e1e"
      />
    </svg>
  </NuxtLinkLocale>
</template>

<script setup lang="ts">
import type { DefaultLayoutQuery } from '#gql';

const TAB_OFFSET_DEFAULT = 16;
const UPDATE_DELAY = 150;

withDefaults(defineProps<{
  tabs: DefaultLayoutQuery['mainNavigation']['items'];
}>(), {  })

const tabOffset = ref<number>(TAB_OFFSET_DEFAULT);

const {
  firstLevelActiveIndex,
  updateFirstLevelActiveIndex,
} = useNavigationActiveIndex();

const {
  onMouseEnter,
  setVisibilityIndicator,
  tabIndicatorStyle,
  unsetLeftAndWidth,
  updateHover,
} = useTabIndicator(tabOffset, false);

const outsideTabWrapper = ref(true);

const { isBpMobile } = useAppBreakpoints();

const router = useRouter();

const { getStoryblokUrl } = useStoryblokData();

watch(firstLevelActiveIndex, (after) => {
  if (after === -1) {
    outsideTabWrapper.value = true;
    setVisibilityIndicator(false);
    unsetLeftAndWidth();
  }
});

const {
  start,
  stop,
} = useTimeoutFn((index: number, onlyFirstLevel: boolean) => {
  updateFirstLevelActiveIndex(index, onlyFirstLevel);
}, UPDATE_DELAY);

const onMouseEnterNavigation = (event: MouseEvent, index: number, onlyFirstLevel = false) => {
  outsideTabWrapper.value = false;
  onMouseEnter(event);
  start(index, onlyFirstLevel);
};

const touchStart = (event: TouchEvent, index: number, url: string, onlyFirstLevel = false) => {
  if (!isBpMobile.value) {
    event.preventDefault();
  }

  if (index === firstLevelActiveIndex.value) {
    outsideTabWrapper.value = true;
    updateFirstLevelActiveIndex(-1);
    router.push(url);
  } else {
    outsideTabWrapper.value = false;
    updateHover(event.currentTarget as HTMLInputElement);
    updateFirstLevelActiveIndex(index, onlyFirstLevel);
  }
};

const onMouseLeave = () => {
  stop();
  if (firstLevelActiveIndex.value < 0) {
    setVisibilityIndicator(false);
  }
};
</script>

<template>
  <div
    class="main-navigation-tabs relative flex"
    data-cy="main-navigation-tabs"
  >
    <span
      v-for="(tabItem, index) in tabs"
      :id="`ui-tab-${index}`"
      :key="tabItem._uid"
      class="flex flex-1 select-none items-center justify-center"
      @click="onMouseEnterNavigation($event, -1)"
      @mouseenter="onMouseEnterNavigation($event, index, !tabItem.hasFlyoutItems)"
      @mouseleave="onMouseLeave"
      @touchstart="touchStart($event, index, getStoryblokUrl(tabItem.link), !tabItem.hasFlyoutItems)"
    >
      <NuxtLink
        class="px-3.5 py-2.5 md:px-4"
        :class="{
          'font-bold': tabItem.boldText,
        }"
        :style="{
          color: tabItem.textColor,
        }"
        :to="getStoryblokUrl(tabItem.link)"
      >
        <span
          class="text-xs"
          v-text="tabItem.title"
        />
      </NuxtLink>
    </span>
    <span
      class="transition-tab pointer-events-none absolute bottom-0 h-0.5 rounded-t-sm bg-dark"
      :style="outsideTabWrapper ? {} : tabIndicatorStyle"
    />
  </div>
</template>

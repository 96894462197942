<script setup lang="ts">
import { UserCircleIcon } from 'lucide-vue-next';

const { customer } = useCustomer();
const { locale } = useI18n();
const { openDialog: openAuthDialog } = useAuthDialog();
</script>

<template>
  <component
    :is="customer?.isLoggedIn ? 'a' : 'button'"
    class="flex size-8 items-center justify-center rounded-full border border-grey bg-white transition duration-300 ease-in-out hover:border-2 hover:border-dark md:size-10"
    type="button"
    :href="customer?.isLoggedIn ? `/${locale}/account` : null"
    @click="!customer?.isLoggedIn && openAuthDialog()"
  >
    <div
      v-if="customer?.isLoggedIn"
      class="flex size-6 items-center justify-center rounded-full bg-dark"
    >
      <span
        class="text-sm font-bold uppercase text-white"
        v-text="customer.firstname?.charAt(0)"
      />
    </div>
    <UserCircleIcon
      v-else
      class="size-6 text-dark"
    />
  </component>
</template>

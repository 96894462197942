import type { LocaleCode } from '../config/locale';

const localeToTrustpilotLanguageMap: Record<LocaleCode, string> = {
  de: 'de',
  'de-at': 'de',
  'de-ch': 'de',
  'de-it': 'de',
  'de-li': 'de',
  'de-lu': 'de',
  en: 'en',
  es: 'es',
  fr: 'fr',
  'fr-be': 'fr',
  'fr-ch': 'fr',
  'fr-lu': 'fr',
  it: 'it',
  'it-ch': 'it',
  nl: 'nl',
  'nl-be': 'nl',
  uk: 'en',
};

const getTrustpilotLanguageFromLocaleCode = (localeCode: LocaleCode) => localeToTrustpilotLanguageMap[localeCode];

export { getTrustpilotLanguageFromLocaleCode };

export type AuthDialogView = 'login' | 'registration' | 'forgot-password';

const useAuthDialog = () => {
  const searchParams = useUrlSearchParams('history', { removeFalsyValues: true });
  const isDialogOpen = useState('AuthDialogOpen', () => false);
  const activeView = useState<AuthDialogView>('AuthDialogView', () => 'login');

  const openDialog = (view?: AuthDialogView) => {
    activeView.value = view || 'login';
    isDialogOpen.value = true;
  };

  const closeDialog = () => {
    isDialogOpen.value = false;
    searchParams.authView = '';
  };

  const setView = (newView: AuthDialogView) => {
    activeView.value = newView;
  };

  return {
    activeView,
    closeDialog,
    isDialogOpen,
    openDialog,
    setView,
  };
};

export { useAuthDialog };

<script setup lang="ts">
import { UserCircleIcon } from 'lucide-vue-next';
import { Logger } from 'utils';
import {
  object,
  string,
} from 'yup';

const {
  closeDialog,
  setView,
} = useAuthDialog();
const { login: loginCustomer } = useCustomer();
const {
  creator,
  login: loginCreator,
} = useCreator();

const tabValue = ref<'creator' | 'customer'>('customer');

watch(tabValue, (newValue) => {
  if (newValue === 'creator' && creator.value?.token) {
    window.open(new URL('/creator-suite', window.location.origin), '_blank');
    closeDialog();
  }
});

const serverError = ref<string | null>(null);
const {
  handleSubmit,
  isSubmitting,
} = useForm({
  initialValues: {},
  validationSchema: toTypedSchema(object({
    email: string().required('form.errorMessage.required')
      .email('form.errorMessage.email'),
    password: string().required('form.errorMessage.required'),
  })),
});

const onSubmit = handleSubmit(async (formValues) => {
  serverError.value = null;

  if (tabValue.value === 'creator') {
    try {
      await loginCreator(formValues);
      window.open(new URL('/creator-suite', window.location.origin), '_blank');
      closeDialog();
    } catch (e) {
      if (e instanceof Error) {
        serverError.value = e.message;
      }

      Logger.error('Login failed');
    }
    return;
  }

  try {
    await loginCustomer(formValues);

    closeDialog();
  } catch (e) {
    if (e instanceof Error) {
      serverError.value = e.message;
    }

    Logger.error('Could not log in', e);
  }
});
</script>

<template>
  <div>
    <div class="flex flex-col items-center justify-center bg-white p-8 pb-12 text-center">
      <UserCircleIcon class="mb-2 size-10" />
      <h2 class="my-0 text-xl font-bold">
        {{ $t('authDialog.login.headline') }}
      </h2>
      <p class="mb-0 mt-1 font-medium">
        {{ $t('authDialog.login.description') }}
      </p>
    </div>
    <div
      class="p-8"
      style="background-image: linear-gradient(to bottom, var(--color-grey-light) 0, var(--color-white) 1rem)"
    >
      <Tabs
        class="-mx-1 -mt-14 mb-8"
        :model-value="tabValue"
        @update:model-value="newValue => tabValue = String(newValue) as typeof tabValue"
      >
        <TabsList class="w-full border-4 border-white">
          <TabsTrigger value="customer">
            {{ $t('authDialog.customer') }}
          </TabsTrigger>
          <TabsTrigger value="creator">
            {{ $t('authDialog.creator') }}
          </TabsTrigger>
        </TabsList>
      </Tabs>

      <form
        class="grid grid-cols-1 gap-5"
        @submit.prevent="onSubmit()"
      >
        <UiAlert
          v-if="serverError"
          type="error"
          :body="serverError"
        />
        <FormField
          v-slot="{ componentField }"
          name="email"
        >
          <FormItem>
            <FormLabel :label="$t('authDialog.login.email')" />
            <FormControl>
              <Input
                :id="componentField.name"
                v-bind="componentField"
                autocomplete="email"
              />
              <FormMessage />
            </FormControl>
          </FormItem>
        </FormField>

        <FormField
          v-slot="{ componentField }"
          name="password"
        >
          <FormItem>
            <FormLabel :label="$t('authDialog.login.password')" />
            <FormControl>
              <PasswordInput
                :id="componentField.name"
                v-bind="componentField"
                autocomplete="password"
              />
              <FormMessage />
            </FormControl>
          </FormItem>
        </FormField>

        <Button
          type="submit"
          variant="cta"
          :disabled="isSubmitting"
          :is-loading="isSubmitting"
        >
          {{ $t('authDialog.login.submit') }}
        </Button>

        <div class="text-center text-xs">
          <a
            class="text-grey-semidark underline"
            href="?authView=forgot-password"
            @click.prevent="setView('forgot-password')"
          >
            {{ $t('authDialog.login.forgotPassword') }}
          </a>
        </div>
      </form>
    </div>

    <div
      v-if="tabValue === 'customer'"
      class="relative p-6"
      style="background-image: linear-gradient(to bottom, var(--color-grey-light) 0, var(--color-white) 1rem)"
    >
      <p class="text-center font-medium">
        {{ $t('authDialog.login.newToJuniqe') }}
        <a

          class="font-semibold text-purple underline"
          href="?authView=registration"
          @click.prevent="setView('registration')"
        >
          {{ $t('authDialog.login.register') }}
        </a>
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { FORM_ITEM_INJECTION_KEY } from '../../../lib/injection-token';
import type { HTMLAttributes } from 'vue';

const props = defineProps<{
  class?: HTMLAttributes['class'];
  gridLayout?: boolean;
}>();

const id = useId();
provide(FORM_ITEM_INJECTION_KEY, id);
</script>

<template>
  <div :class="cn(gridLayout ? 'sm:grid sm:grid-cols-3 sm:items-start sm:gap-4' : 'flex flex-col gap-2', props.class)">
    <slot />
  </div>
</template>

<script setup lang="ts">
import {
  TabsTrigger,
  type TabsTriggerProps,
  useForwardProps,
} from 'radix-vue';
import { type HTMLAttributes } from 'vue';

const props = defineProps<TabsTriggerProps & { class?: HTMLAttributes['class'] }>();

const delegatedProps = computed(() => {
  const {
    class: _,
    ...delegated
  } = props;

  return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <TabsTrigger
    v-bind="forwardedProps"
    :class="cn(
      'inline-flex items-center justify-center whitespace-nowrap rounded-full flex-1 px-4 py-1.5 text-sm font-semibold text-purple ring-offset-white transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-white data-[state=active]:text-dark',
      props.class,
    )"
  >
    <slot />
  </TabsTrigger>
</template>

import { State } from '@/config/state';

const useBurgerNavigation = () => {
  const isBurgerNavVisible = useState<boolean>(State.IsBurgerNavigationVisible, () => false);

  const selectedSecondLevelIndex = useState<number>(State.SelectedSecondLevelIndex, () => -1);

  const selectedThirdLevelIndex = useState<number>(State.SelectedThirdLevelIndex, () => -1);

  const isBurgerNavigationVisible = computed(() => isBurgerNavVisible.value);

  const getSelectedSecondLevelIndex = computed(() => selectedSecondLevelIndex.value);

  const getSelectedThirdLevelIndex = computed(() => selectedThirdLevelIndex.value);

  const isSecondLevelOpen = computed(() => selectedSecondLevelIndex.value !== -1);

  const isThirdLevelOpen = computed(() => selectedThirdLevelIndex.value !== -1);

  const setSecondLevel = (index: number) => {
    selectedSecondLevelIndex.value = index;
  };

  const setThirdLevel = (index: number) => {
    selectedThirdLevelIndex.value = index;
  };

  const setIsBurgerNavigationVisible = (isVisible: boolean) => {
    // Prevent body scrolling if burger menu is visible
    if (isVisible) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }
    isBurgerNavVisible.value = isVisible;
  };

  return {
    getSelectedSecondLevelIndex,
    getSelectedThirdLevelIndex,
    isBurgerNavigationVisible,
    isSecondLevelOpen,
    isThirdLevelOpen,
    setIsBurgerNavigationVisible,
    setSecondLevel,
    setThirdLevel,
  };
};

export { useBurgerNavigation };

<script setup lang="ts">
import {
  HeartIcon,
  ShoppingCartIcon,
} from 'lucide-vue-next';
import { HeaderLinks } from '@/config/header-links';
import type { DefaultLayoutQuery } from '#gql';

defineProps<{ headerContent: DefaultLayoutQuery['header'] }>();

const { customer } = useCustomer();

const { getStoryblokUrl } = useStoryblokData();

const { BASE_URL } = useBaseUrl();

const creatorSuiteLink = `${BASE_URL}/creator-suite`;
</script>

<template>
  <div class="header-desktop container flex w-full flex-col py-0">
    <HeaderTopBar :header-content="headerContent" />
    <div class="relative flex w-full items-center">
      <span class="mr-5 flex w-48 shrink-0 items-center lg:mr-10">
        <CtaButton
          v-if="headerContent.ctaButtonText"
          :button-link-creator="creatorSuiteLink"
          :button-link-storyblok="getStoryblokUrl(headerContent.ctaButtonLinkLeft)"
          :button-text="headerContent.ctaButtonText"
          :button-text-logged-in="headerContent.ctaButtonTextLoggedIn"
        />
      </span>
      <div class="header-search z-60 relative mr-4 w-full grow">
        <SearchInput is-desktop-header />
      </div>
      <span
        class="shring-0 flex w-48 justify-end text-right"
        :class="[!!customer?.cartArticlesAmount ? '-mr-5' : 'mr-0']"
      >
        <UserButton />
        <IconButton
          with-border
          :button-link="HeaderLinks.Wishlist"
          :icon="HeartIcon"
        />
        <IconButton
          is-link-external
          with-border
          :badge-info="customer?.cartArticlesAmount"
          :button-link="HeaderLinks.Cart"
          :icon="ShoppingCartIcon"
        />
      </span>
    </div>
  </div>
</template>

import { State } from '@/config/state';

const useNavigationActiveIndex = () => {
  const firstLevelActiveIndex = useState<number>(State.FirstLevelActiveIndex, () => -1);

  const secondLevelActiveIndex = useState<number>(State.SecondLevelActiveIndex, () => 0);

  const thirdLevelActiveIndex = useState<number>(State.ThirdLevelActiveIndex, () => 0);

  const updateFirstLevelActiveIndex = (newIndex: number, onlyFirstLevel = false) => {
    // If only the first level is available, the rest of the functionality can be skipped
    if (onlyFirstLevel) {
      firstLevelActiveIndex.value = newIndex;
      return;
    }

    // If first level changes every level is set to the default
    firstLevelActiveIndex.value = newIndex;
    secondLevelActiveIndex.value = 0;
    thirdLevelActiveIndex.value = 0;
  };

  const updateSecondLevelActiveIndex = (newIndex: number) => {
    // If second level changes third level is also set to the default
    secondLevelActiveIndex.value = newIndex;
    thirdLevelActiveIndex.value = 0;
  };

  const updateThirdLevelActiveIndex = (newIndex: number) => {
    thirdLevelActiveIndex.value = newIndex;
  };

  return {
    firstLevelActiveIndex,
    secondLevelActiveIndex,
    thirdLevelActiveIndex,
    updateFirstLevelActiveIndex,
    updateSecondLevelActiveIndex,
    updateThirdLevelActiveIndex,
  };
};

export { useNavigationActiveIndex };

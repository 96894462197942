const disableBodyScroll = () => {
  document.body.style.position = 'fixed';
  document.body.style.width = '100%';
};

const enableBodyScroll = () => {
  document.body.style.position = 'unset';
  document.body.style.width = 'unset';
};

export {
  disableBodyScroll,
  enableBodyScroll,
};

<script setup lang="ts">
import {
  TooltipArrow,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipRoot,
  type TooltipRootEmits,
  type TooltipRootProps,
  TooltipTrigger,
  useForwardPropsEmits,
} from 'radix-vue';
import { type HTMLAttributes } from 'vue';

const TOOLTIP_TIMEOUT = 100;

const props = defineProps<TooltipRootProps & { arrowClass?: HTMLAttributes['class']; class?: HTMLAttributes['class'] }>();

const emits = defineEmits<TooltipRootEmits>();

const delegatedProps = computed(() => {
  const {
    class: _,
    ...delegated
  } = props;

  return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);

const isOpen = ref(false);
let closeTimeout: ReturnType<typeof setTimeout>;

const isTouchDevice = () => 'ontouchstart' in window || navigator.maxTouchPoints > 0;

const toggleTooltip = () => {
  if (isTouchDevice()) {
    isOpen.value = !isOpen.value;
  }
};

const closeTooltip = () => {
  isOpen.value = false;
};

const closeTooltipOnClickOutside = (event: MouseEvent | TouchEvent) => {
  const target = event.target as Element;
  const outsideClick = !(target).closest('.tooltip-trigger') && !target.closest('.tooltip-content');

  if (outsideClick) {
    closeTooltip();
  }
};

const handleMouseEnter = () => {
  if (!isTouchDevice()) {
    clearTimeout(closeTimeout);
    isOpen.value = true;
  }
};

const handleMouseLeave = () => {
  if (!isTouchDevice()) {
    closeTimeout = setTimeout(closeTooltip, TOOLTIP_TIMEOUT);
  }
};

useEventListener(window, 'click', closeTooltipOnClickOutside);
useEventListener(window, 'touchstart', closeTooltipOnClickOutside);
</script>

<template>
  <TooltipProvider
    disable-closing-trigger
    :delay-duration="0"
  >
    <TooltipRoot
      v-bind="forwarded"
      :open="isOpen"
    >
      <TooltipTrigger as-child>
        <div
          class="tooltip-trigger"
          @click="toggleTooltip"
          @mouseenter="handleMouseEnter"
          @mouseleave="handleMouseLeave"
        >
          <slot name="trigger" />
        </div>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent
          class="tooltip-content"
          :class="cn(
            'rounded-lg bg-semidark p-2 text-xs font-medium text-white shadow-sm',
            props.class,
          )"
          :side-offset="4"
          @mouseenter="handleMouseEnter"
          @mouseleave="handleMouseLeave"
        >
          <slot name="content" />
          <TooltipArrow :class="[props.arrowClass]" />
        </TooltipContent>
      </TooltipPortal>
    </TooltipRoot>
  </TooltipProvider>
</template>

<script lang="ts" setup>
import {
  EyeIcon,
  EyeOffIcon,
} from 'lucide-vue-next';
import { type HTMLAttributes } from 'vue';

const props = defineProps<{ class?: HTMLAttributes['class'] }>();

const passwordVisible = ref(false);

const delegatedProps = computed(() => {
  const {
    class: _,
    ...delegated
  } = props;

  return delegated;
});
</script>

<template>
  <div class="relative">
    <Input
      :class="cn('w-full', props.class)"
      :type="passwordVisible ? 'text' : 'password'"
      :v-bind="delegatedProps"
    />
    <div class="absolute inset-y-0 right-0 flex items-center pr-2">
      <button
        class="text-gray-500 hover:text-gray-700 focus-visible:ring-gray-950 p-1.5 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
        type="button"
        @click="passwordVisible = !passwordVisible"
      >
        <template v-if="passwordVisible">
          <EyeOffIcon class="size-5" />
        </template>
        <template v-else>
          <EyeIcon class="size-5" />
        </template>
      </button>
    </div>
  </div>
</template>

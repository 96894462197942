export const productTypesRecognition = {
  de: [
    {
      product: 't-shirt',
      variations: 'T-Shirt,T-Shirts,Shirt,Oberteil,Kurzarmshirt,Tshirt,TShirt,T-shrit,T-shrt,Shirts,Oberteille,Kurzarmshirte',
    },
    {
      product: 'hoodie',
      variations: 'Hoodie,Hoodies,Kapuzenpullover,Kapuzensweatshirt,Hoody,Hoddie,Hoodi,Hudie,Kapuzenpullovr,Kapuzenschrit,Sweater',
    },
    {
      product: 'sweatshirt',
      variations: 'Sweatshirt,Sweatshirts,Pullover,Sweater,Langarmshirt,Swetshirt,Swatshirt,Swater,Pulovr,Sweter',
    },
    {
      product: 't-shirt-kid',
      variations: 'Kinder T-Shirt,Kinder T-Shirts,Kinder Shirt,Kids Shirt,Kindershirt,Kids Tshirt,Kinder T-shrit,Kid Shirt,Kinder Shirte,Kindershirte',
    },
    {
      product: 'hoodie-kid',
      variations: 'Kinder Hoodie,Kinder Hoodies,Kinder Kapuzenpullover,Kids Hoodie,Kids Hoody,Kids Hoodi,Kinder Hoodie,Kapuzenpullis,Kinder Sweater',
    },
    {
      product: 'sweatshirt-kid',
      variations: 'Kinder Sweatshirt,Kinder Sweatshirts,Kinder Pullover,Kids Sweater,Kinder Langarmshirt,Kids Swetshirt,Kinder Sweat,Swater,Kinder Pulovr,Kinder Sweaater',
    },
    {
      product: 'romper-short-sleeve-baby',
      variations: 'Body,Bodys,Babybody,Einteiler,Baby-Einteiler,Boddy,Bodi,Bady,Babybodys,Einteiller',
    },
    {
      product: 'rompoer-long-sleeve-baby',
      variations: 'Strampler,Strampler,Babystrampler,Overall,Stramplr,Srampler,Strampler,Babystramplr,Overals',
    },
    {
      product: 'bib-baby',
      variations: 'Lätzchen,Lätzchen,Sabberlätzchen,Babylatz,Babylätzchen,Lätchen,Lätzchn,Lätzschen,Sabbertüchr,Lätzn',
    },
    {
      product: 't-shirt-baby',
      variations: 'Baby T-Shirt,Baby T-Shirts,Baby Shirt,Babyoberteil,Baby Tshrt,Baby T-Shrit,Bab Tshrt,Babyshirte,Babyoberteille',
    },
    {
      product: 'sweatshirt-baby',
      variations: 'Baby Sweatshirt,Baby Sweatshirts,Baby Pullover,Baby Sweater,Baby Langarm,Baby Swtshirt,Swetshrt,Swater,Babypulovr,Baby Sweater',
    },
    {
      product: 'bag-tote',
      variations: 'Stofftasche,Stofftaschen,Einkaufstasche,Tragetasche,Jutebeutel,Stofftashe,Stoftasche,Stof Tasche,Tragetaschn,Einkaufstaschn',
    },
    {
      product: 'mug-ceramic',
      variations: 'Tasse,Tassen,Becher,Kaffeetasse,Tase,Tassee,Tasee,Kaffeetassn,Bechr',
    },
    {
      product: 'cushion-decorative',
      variations: 'Kissen,Kissen,Kopfkissen,Sofakissen,Dekokissen,Kissn,Kissenn,Kisn,Sofaküssn,Dekoküssn',
    },
    {
      product: 'curtain-shower',
      variations: 'Duschvorhang,Duschvorhänge,Badezimmer Vorhang,Duschabtrennung,Duschvorang,Dchvorhang,Duschorhang,Badezimmer-Vorhäng,Duschabtretung',
    },
    {
      product: 'towel-tea',
      variations: 'Geschirrtuch,Geschirrtücher,Küchentuch,Abtrockentuch,Trockentuch,Geschirtuch,Geschirtuck,Gschirrtuch,Trockentücher,Küchenhandtüchr',
    },
    {
      product: 'towel-bath',
      variations: 'Handtuch,Handtücher,Badetuch,Badezimmerhandtuch,Handtuck,Handtuh,Handtuech,Badetücher,Handtücher',
    },
    {
      product: 'towel-beach',
      variations: 'Strandtuch,Strandtücher,Strandbadetuch,Liegetuch,Standtuch,Strandtuck,Strandtuh,Badetücher,Strandbadetücher',
    },
    {
      product: 'blanket-fleece',
      variations: 'Fleecedecke,Fleecedecken,Kuscheldecke,Wohndecke,Sofadecke,Flecdecke,Fleesedecke,Fleecede,Kuscheldekn,Wohndekn',
    },
    {
      product: 'doormat,Fußmatte',
      variations: 'Fußmatten,Eingangsmatte,Türmatte,Schmutzfangmatte,Fußtmatte,Fmatte,Fußmte,Fußmaten,Eingangsmaten',
    },
    {
      product: 'notebook',
      variations: 'Notizbuch,Notizbücher,Tagebuch,Schreibbuch,Journal,Ntozbuch,Notibch,Ntizbuch,Tagebücher,Journals',
    },
    {
      product: 'sticker',
      variations: 'Aufkleber,Aufkleber,Sticker,Etikett,Kleber,Aufklebr,Aukkleber,Stickr,Klebr,Aufklebber,Etikettn',
    },
    {
      product: 'phone-cover-iphone',
      variations: 'Handyhülle,Handyhüllen,Smartphone-Hülle,Case,Cover,Handihülle,Handyülle,Handyüle,Mobiltelefonschalen,Handycasen',
    },
    {
      product: 'poster',
      variations: 'Poster,Poster,Wandbild,Kunstdruck,Plakat,Print,Druck,Postr,Poaster,Poster,Wandbilder,Plakate',
    },
    {
      product: 'leinwand',
      variations: 'Leinwand,Leinwände,Canvas,Leinwandbild,Keilrahmen,Linwand,Lienwand,Leinwd,Wandbilder,Keilrahmenbilder',
    },
    {
      product: 'wallpaper',
      variations: 'Fototapete,Fototapeten,Wandtapete,Tapete,Fotowand,Ftotapete,Fototapte,Ftotapte,Wandtaptn,Fotowänd',
    },
    {
      product: 'wall-art-framed',
      variations: 'Bilderrahmen,,Rahmen,Bilderramen,Bilderamen,Bilderahmen,',
    },
  ],
  es: [
    {
      product: 't-shirt',
      variations: 'Camiseta,Camisetas,Playera,Polo,Camiseta de manga corta,Camisita,Camisets,Cmiseta,Pllyera,Pllyeras',
    },
    {
      product: 'sweatshirt',
      variations: 'Sudadera,Sudaderas,Jersey,Suéter,Polera,Sudadr,Sudader,Sudra,Suetero,Sueteros',
    },
    {
      product: 'hoodie',
      variations: 'Sudadera Con Capucha,Sudaderas Con Capucha,Suéter con capucha,Polerón,Hoodi,Sudadrcapch,Suddr Cph,Plrón,Plróns',
    },
    {
      product: 't-shirt-kid',
      variations: 'Camiseta Infantil,Camisetas Infantiles,Playera infantil,Camiseta para niños,Cmiset infantil,Cmseta Inf,Cmiset niñ,Pllera niño,Plleras niños',
    },
    {
      product: 'hoodie-kid',
      variations: 'Sudadera con capucha para niños,Sudaderas con capucha para niños,Sudadera con capucha para niños,sudadera con capucha para niños,Sudadera con capucha para niños,sudadera con capucha para niños,sudadera con capucha para niños,Sudaderas con capucha,suéteres para niños.',
    },
    {
      product: 'sweatshirt-kid',
      variations: 'Sudadera Infantil,Sudaderas Infantiles,Jersey infantil,Polera para niños,Sdadera infantil,Sudr Infantil,Infnt Sudadera,Niñ Polra,Niñ Polras',
    },
    {
      product: 'romper-short-sleeve-baby',
      variations: 'Pelele,Peleles,Mameluco,Enterizo,Pelel,Pel,Pelelle,Ntrzo,Mmlco,Ntrzos,Mmlcos',
    },
    {
      product: 'rompoer-long-sleeve-baby',
      variations: 'Babero,Baberos,Babero para bebé,Bbero,Babro,Bavero,Srvtt bb,Srvtts bb',
    },
    {
      product: 'bib-baby',
      variations: 'Camiseta de Bebé,Camisetas de Bebé,Playera bebé,Ropa bebé,Camist Bebé,Cmseta Bb,Bb Camiseta,Pllera bb,Plleras bb',
    },
    {
      product: 't-shirt-baby',
      variations: 'Suéter de Bebé,Suéteres de Bebé,Sudadera para bebé,Sutr Bebé,Bbe Sudadr,Bb suetr,Bb suetrs',
    },
    {
      product: 'sweatshirt-baby',
      variations: 'Bolsa de Tela,Bolsas de Tela,Bolsa ecológica,Bolsa reusable,Bosa Tela,Bolsa d Tela,Ttela Bolsa,Bolsa ecolga,Bolsas ecologas',
    },
    {
      product: 'bag-tote',
      variations: 'Bolsa de tela,Bolsas de tela,Bolsa de compras,bolsa de transporte,bolsa de yute.,Bolsa de tela,bolsa de tela,bolsa de tela,Bolsa de transporte,bolsa de compras',
    },
    {
      product: 'mug-ceramic',
      variations: 'Taza,Tazas,Mug,Jarra,Vasija,Tz,Tza,Taaza,Mgg,Mggs',
    },
    {
      product: 'cushion-decorative',
      variations: 'Cojín,Cojines,Almohada,Cojín decorativo,Cijn,Cojin,Almhada decor,Almohadas decors',
    },
    {
      product: 'curtain-shower',
      variations: 'Cortina de Ducha,Cortinas de Ducha,Cortina de baño,Crtn,Cortna,Ctna baño,Ctnas baño',
    },
    {
      product: 'towel-tea',
      variations: 'Paño de Cocina,Paños de Cocina,Trapo,Toalla de cocina,Panyo Cocina,Payo,Pño Cocina,Trpo,Trpos',
    },
    {
      product: 'towel-bath',
      variations: 'Toalla,Toallas,Toalla de baño,Toll,Toaya,Taolla,Toa,Toas',
    },
    {
      product: 'towel-beach',
      variations: 'Toalla de Playa,Toallas de Playa,Toalla para la playa,Toalla de sol,Toll Playa,Toayla Playa,Pllya Toalla,Tsol,Toll playa,Toll playas',
    },
    {
      product: 'blanket-fleece',
      variations: 'Manta Polar,Mantas Polares,Cobija,Manta acogedora,Mant Polr,Mnta Ploar,Cbi acogdr,Cbis acogdrs',
    },
    {
      product: 'doormat',
      variations: 'Felpudo,Felpudos,Alfombra de entrada,Tapete,Felpdo,Flpdo,Fepudo,Alfombra ntr,Alfombras ntrs',
    },
    {
      product: 'notebook',
      variations: 'Cuaderno,Cuadernos,Libreta,Diario,Libro de notas,Cuadeno,Caderno,Cuadrno,Crnt libro,Crnt libros',
    },
    {
      product: 'sticker',
      variations: 'Calcomanía,Calcomanías,Pegatina,Etiqueta,Calcomina,Clcomnia,Ccalcomania,Etiquett,Etiquetts',
    },
    {
      product: 'phone-cover-iphone',
      variations: 'Funda de Teléfono,Fundas de Teléfono,Carcasa,Cubierta de móvil,Fnda Tel,Funda Tlfn,Fnda Tlfno,Teléfono cubta,Teléfonos cubtas',
    },
    {
      product: 'poster',
      variations: 'Póster,Pósters,Cartel,Impresión,Obra de arte mural,Pstr,Póste,Poster,Cart,Muralo,Cartels,Murals',
    },
    {
      product: 'leinwand',
      variations: 'Lienzo,Lienzos,Pintura,Lona,Mural,Lino,Lenzo,Lienso,Pintura decra,Pinturas decors',
    },
    {
      product: 'wallpaper',
      variations: 'Papel Tapiz,Papeles Tapiz,Mural,Decoración de pared,Pap Tapiz,Papel tpiz,Papl Tiz,Murral,Papeles murales',
    },
    {
      product: 'wall-art-framed',
      variations: 'Marco de Fotos,,marcos de cuadros,nombres de cuadros,marcos de cuadros',
    },
  ],
  fr: [
    {
      product: 't-shirt',
      variations: 'T-Shirt,T-Shirts,Tee,Haut,T-shirt à manches courtes,Tshrt,TShirt,T-shrit,T-shrt,Tshirt,Tsirt,Shrt,T-shrts à mnges,Hautts',
    },
    {
      product: 'hoodie',
      variations: 'Hoodie,Hoodies,Sweat à capuche,Pull à capuche,Hoody,Hoddie,Hoodi,Hudie,Capchon,Sweatcapuche,Sweaters à capch,Pulls à cph',
    },
    {
      product: 'sweatshirt',
      variations: 'Sweatshirt,Sweatshirts,Pull,Chandail,Swetshirt,Swetshrt,Swetshrit,Chandai,Pulle,Chandls,Suetrs',
    },
    {
      product: 't-shirt-kid',
      variations: 'T-Shirt Enfant,T-Shirts Enfant,Tee Enfant,Haut Enfant,Tee Enfant,Enft Tee,Tshirt Enfant,Enfn Tee,TShirt Enfat,T-shirts Enft',
    },
    {
      product: 'hoodie-kid',
      variations: 'Hoodie Enfant,Hoodies Enfant,Sweat à capuche Enfant,Hoody Enfant,Hoodie enfant,Hoddie Enfant,Capchon Enfant,Hoddy Enfant,Pulls à cappchs',
    },
    {
      product: 'sweatshirt-kid',
      variations: 'Sweat Enfant,Sweats Enfant,Pull Enfant,Chandail Enfant,Swet Enfant,Swt Enfant,Swetshrt Enfant,Pulli Enfant,Chandial Enfant,Chandils Enfts,Pulls Infnts',
    },
    {
      product: 'romper-short-sleeve-baby',
      variations: 'Body,Bodies,Body bébé,Combinaison bébé,Boddie,Bodi,Boddy,Bodie,Bddy,Bds bébés,Combnaisns bb',
    },
    {
      product: 'rompoer-long-sleeve-baby',
      variations: 'Grenouillère,Grenouillères,Combinaison bébé,Pyjama bébé,Grnouillère,Grenouille,Grenoullière,Grenoulleire,Grnouiler,Grnouillères,Combns bb',
    },
    {
      product: 'bib-baby',
      variations: 'Bavoir,Bavoirs,Bavoir bébé,Serviette bébé,Bvoir,Bvr,Bavr,Bavir,Saviertte,Bvoirs,Bvrs bb',
    },
    {
      product: 't-shirt-baby',
      variations: 'T-Shirt Bébé,T-Shirts Bébé,Tee bébé,Haut bébé,Tee bébé,T-shrt bébé,Tshirt bébé,TShirt Bb,Tee Bbbé,Tops bb,Hauts bb',
    },
    {
      product: 'sweatshirt-baby',
      variations: 'Sweat Bébé,Sweats Bébé,Pull bébé,Chandail bébé,Sweat Bb,Swt Bébé,Swet bb,Chnail Bb,Pullr Bébé,Chandails bb',
    },
    {
      product: 'bag-tote',
      variations: 'Tote Bag,Tote Bags,Sac cabas,Sac en toile,Sac réutilisable,Tote Bg,Totebg,Tot bag,Saccbas,Sacc élogique,Sccabs,Borsacs',
    },
    {
      product: 'mug-ceramic',
      variations: 'Tasse,Tasses,Mug,Tasse à café,Gobelet,Tase,Tasee,Tassse,Goblt,Tzée,Goblets,Gbelets',
    },
    {
      product: 'cushion-decorative',
      variations: 'Coussin,Coussins,Oreiller,Découssin,Coussin décoratif,Cousn,Cousin,Coussn,Oreiller,Découssin,Decoussins,Orillers',
    },
    {
      product: 'curtain-shower',
      variations: 'Rideau de Douche,Rideaux de Douche,Rideau de bain,Rideau pour la douche,Rideau Dche,Rideu Dch,Rideau dduche,Rideau Bain,Dche Rideaux,Ridaus,Rideaudchs',
    },
    {
      product: 'towel-tea',
      variations: 'Torchons,Torchons,Essuie-mains,Linges,Serviette de cuisine,Trchon,Tochon,Trchons,Torchon,Chifon,Servetts Cuisine',
    },
    {
      product: 'towel-bath',
      variations: 'Serviette,Serviettes,Serviette de bain,Serviette pour les mains,Serviett,Servete,Serviettn,Servett,Serveet,Servietts',
    },
    {
      product: 'towel-beach',
      variations: 'Serviette de Plage,Serviettes de Plage,Linge de plage,Drap de bain,Serviett Plge,Serv Plage,Serv Plge,Serv Plge,Plage Tll,Drps de plages',
    },
    {
      product: 'blanket-fleece',
      variations: 'Couverture en Polaire,Couvertures en Polaire,Plaid,Couverture douce,Plaid en polaire,Couvertur Polr,Couvert Polr,Cuvrt Polaire,Plaid Polair,Couvert polaire,Couvrtrs douces',
    },
    {
      product: 'doormat',
      variations: 'Paillasson,Paillassons,Tapis d’entrée,Tapis,Tapis de sol,Paillason,Pailason,Paillsson,Pallasson,Tapis Entree,Tapiss d’etrées',
    },
    {
      product: 'notebook',
      variations: 'Cahier,Cahiers,Carnet,Journal,Livre de notes,Caher,Cahir,Chier,Carnett,Jounal,Dairs',
    },
    {
      product: 'sticker',
      variations: 'Autocollant,Autocollants,Sticker,Étiquette,Décalque,Autocollnt,Autoclnt,Autocollnt,Autoclnt,Stckr,Étiquetts,Decalqs',
    },
    {
      product: 'phone-cover-iphone',
      variations: 'Coque de Téléphone,Coques de Téléphone,Étui téléphone,Housse téléphone,Coque mobile,Cque Tel,Coque Tlphne,Coqe Tel,Étui telphone,Couvr telephone,Housses téléphones',
    },
    {
      product: 'poster',
      variations: 'Affiche,Affiches,Poster,Impression,Oeuvre murale,Affihe,Affie,Affce,Postre,Affie,Impresions',
    },
    {
      product: 'leinwand',
      variations: 'Toile,Toiles,Impression sur toile,Peinture sur toile,Tole,Toilee,Tolie,Cnvase,Table,Peintres sur toiles',
    },
    {
      product: 'wallpaper',
      variations: 'Papier Peint,Papiers Peints,Mural,Décoration murale,Papie Peint,Papierpint,Ppier Peint,Papint,Papel ptent,Decors muraux',
    },
    {
      product: 'wall-art-framed',
      variations: "Cadre photo,Cadre,cadres photo,noms d'images,cadres photo,",
    },
  ],
  it: [
    {
      product: 't-shirt',
      variations: 'Maglietta,Magliette,T-shirt,Top,Maglia,Mglietta,Maglt,Maglieta,Maglt,Mglt,Maglts,Mglts',
    },
    {
      product: 'sweatshirt',
      variations: 'Felpa,Felpe,Maglione,Maglia,Fepa,Flpa,Flepa,Magliono,Maglionos',
    },
    {
      product: 'hoodie',
      variations: 'Felpa con Cappuccio,Felpe con Cappuccio,Maglia con cappuccio,Felpa capp,Felp Cppu,Cppu Felp,Mg con cpuc,Mgs con cpucs',
    },
    {
      product: 't-shirt-kid',
      variations: 'Maglietta Bambino,Magliette Bambino,T-shirt bambino,Top bambino,Mgliett Bambin,Mglietta Bmbo,Bmbin Maglietta,Bmbo Maglt,Bmbi Maglts',
    },
    {
      product: 'hoodie-kid',
      variations: 'Felpa con cappuccio per bambini,Felpe con cappuccio per bambini,Felpa con cappuccio per bambini,felpa con cappuccio per bambini,Felpa con cappuccio per bambini,felpa con cappuccio per bambini,felpa con cappuccio per bambini,Felpe con cappuccio,maglioni per bambini',
    },
    {
      product: 'sweatshirt-kid',
      variations: 'Felpa per bambini,Felpe per bambini,Maglione per bambini,maglione per bambini,maglietta a maniche lunghe per bambini,Felpa per bambini,sudore per bambini,acqua,Pulovr per bambini,maglione per bambini',
    },
    {
      product: 'romper-short-sleeve-baby',
      variations: 'Body,Bodies,Tutina,Body per neonato,Boddy,Bodie,Body,Ttina,Tutine per bb',
    },
    {
      product: 'rompoer-long-sleeve-baby',
      variations: 'Tutina,Tutine,Tuta neonato,Pagliaccetto,Tuttina,Tutin,Tutin,Tt per bb,Bavs bb,Bb bavas',
    },
    {
      product: 'bib-baby',
      variations: 'Bavaglino,Bavaglini,Bavaglia,Bavaglino neonato,Bavlino,Bvlino,Bvaglino,Bav bb,Bb bav,Magliette Nnti',
    },
    {
      product: 't-shirt-baby',
      variations: 'Maglietta Neonato,Magliette Neonato,T-shirt neonato,Mglietta Nt,Nnto Magltta,Nt Mgltta,Felpe Nnti',
    },
    {
      product: 'sweatshirt-baby',
      variations: 'Felpa Neonato,Felpe Neonato,Maglione neonato,Fepa Nt,Nt Felpa,Nnt Flpa,Borse tssuti',
    },
    {
      product: 'bag-tote',
      variations: 'Borsa in Tessuto,Borse in Tessuto,Borsa ecologica,Shopper,Borsa Tsst,Borse Tess,Tessuto Borsa,Bs tessuto,Tzze',
    },
    {
      product: 'mug-ceramic',
      variations: 'Tazza,Tazze,Tazza da caffè,Tazza di tè,Tzz,Taazza,Ttza,Tz,Mugg,Cuscini decors',
    },
    {
      product: 'cushion-decorative',
      variations: 'Cuscino,Cuscini,Guanciale,Cuscino decorativo,Cusn,Cusino,Csino,Cusc decor,Tende bagns',
    },
    {
      product: 'curtain-shower',
      variations: 'Tenda Doccia,Tende Doccia,Tenda da bagno,Tenda per doccia,Tend Doc,Tnda Doc,Doccia Tnda,Tnda bagn,Strofniacci',
    },
    {
      product: 'towel-tea',
      variations: 'Strofinaccio,Strofinacci,Asciugamano,Strofinaccio da cucina,Strof,Stofn,Srofinaccio,Stofniaccio,Bagns',
    },
    {
      product: 'towel-bath',
      variations: 'Asciugamano,Asciugamani,Asciugamano da bagno,Asciugmn,Acgmn,Ascgmano,Bagn,Teli mares',
    },
    {
      product: 'towel-beach',
      variations: 'Telo Mare,Teli Mare,Telo da spiaggia,Tel Mr,Telo Mr,Telmae,Tl mare,Coprte soffie',
    },
    {
      product: 'blanket-fleece',
      variations: 'Coperta di Pile,Coperte di Pile,Plaid,Pile morbido,Coprt Pl,Pile Cprta,Cprta Pile,Coprta soffia,Zerbn,Tappeti entrata',
    },
    {
      product: 'doormat',
      variations: 'Zerbino,Zerbini,Tappeto d’ingresso,Zerb,Zrbino,Zrbn,Tapp entrta,Diari',
    },
    {
      product: 'notebook',
      variations: 'Quaderno,Quaderni,Taccuino,Diario,Libro di note,Quadeno,Qderno,Qadrno,Diario,Adesivs',
    },
    {
      product: 'sticker',
      variations: 'Adesivo,Adesivi,Etichetta,Sticker,Figurina,Adesvo,Adesvo,Adevso,Adsi,Figra,Tel cnvr',
    },
    {
      product: 'phone-cover-iphone',
      variations: 'Custodia Telefono,Custodie Telefono,Cover telefono,Custodia smartphone,Cust Telf,Cust Telefn,Telefn Cstd,Tel cnvr,Stmp murali',
    },
    {
      product: 'poster',
      variations: 'Poster,Poster,Manifesto,Stampa,Poser,Pstr,Posster,Stmpa murale,Tlle',
    },
    {
      product: 'leinwand',
      variations: 'Tela,Tele,Immagine su tela,Pannello,Tel,Tla,Tella,Tlla tela,Carte parati',
    },
    {
      product: 'wallpaper',
      variations: 'Carta da Parati,Carte da Parati,Parato,Muro decorativo,Cart Parati,Cprata,Cata Pati,Parato wall',
    },
    {
      product: 'wall-art-framed',
      variations: 'Cornice,Telaio,cornici,nomi di foto,cornici',
    },
  ],
  nl: [
    {
      product: 't-shirt',
      variations: 'T-shirt,T-shirts,Shirt,Top,Korte mouwen,Tshrt,TShirt,T-shrit,T-shrt,Korte mvwen,Shrt,Schrts,Topjes',
    },
    {
      product: 'hoodie',
      variations: 'Hoodie,Hoodies,Capuchontrui,Sweater,Hoody,Hoddie,Hoodi,Hudie,Capcon,Trui met cpuchon,Sweaters met cpchs',
    },
    {
      product: 'sweatshirt',
      variations: 'Sweater,Sweaters,Trui,Lange mouwen shirt,Sweter,Swater,Swatr,Langemwen,Trrui,Truitjes',
    },
    {
      product: 't-shirt-kid',
      variations: 'Kinder T-shirt,Kinder T-shirts,Kindershirt,Kids shirt,Kind Tshrt,Kids T-shrt,Kinder T-shirt,T Shir Kindr,Kidr trui,Kids truien',
    },
    {
      product: 'hoodie-kid',
      variations: 'Kinder Hoodie,Kinder Hoodies,Kinder capuchontrui,Kids Hoody,Kd Hoodie,Kinder Hoodi,Cphoon,Hdyy,Kinder cpuchns',
    },
    {
      product: 'sweatshirt-kid',
      variations: 'Kinder Sweater,Kinder Sweaters,Kindertrui,Kd Sweater,Sweter,Kndr Swater,Kindrttrui,Lange mouwen,Lange mwens shirts',
    },
    {
      product: 'romper-short-sleeve-baby',
      variations: 'Romper,Rompers,Baby romper,Pakje,Rompr,Romprt,Rompe,Babyrmper,Rompj,Baby’tjes',
    },
    {
      product: 'rompoer-long-sleeve-baby',
      variations: 'Rompertje,Rompertje,Babyrompertje,jumpsuit,Rompertje,rompertje,rompertje,Babyrompertjes,overalls',
    },
    {
      product: 'bib-baby',
      variations: 'Slabbetje,Slabbetjes,Babyslab,Eet slabbetje,Slabbtje,Slabtje,Slabtje,Slaab,Eetstje,Slbb’s,Babers',
    },
    {
      product: 't-shirt-baby',
      variations: 'Baby T-shirt,Baby T-shirts,Baby shirt,Tshirt Bby,Bby T-Shirt,Babtshirt,Bb Shirt,Infnt T Shirt,Baby truien',
    },
    {
      product: 'sweatshirt-baby',
      variations: 'Baby Sweater,Baby Sweaters,Baby trui,Baby lange mouw,Baby Swater,Baby Sweatr,Bby Swtr,Bb Pulvr,Stoffen Tss,Stoffentasjes',
    },
    {
      product: 'bag-tote',
      variations: 'Stoffen tas,Stoffen tassen,Boodschappentas,Herbruikbare tas,Stoffen Ts,Stoffntas,Totebg,Inkshopping tas,Herbuikbare,Beekers',
    },
    {
      product: 'mug-ceramic',
      variations: 'Mok,Mokken,Koffiemok,Beker,Mo,Mook,Mok,Koffiemgg,Kopje Mgg,Decoratiekussens',
    },
    {
      product: 'cushion-decorative',
      variations: 'Kussen,Kussens,Kussen,Hoofdkussen,Decoratiekussen,Kssen,Kusn,Cusen,Sofakussen,Deko kussen,Badmrgordijnen',
    },
    {
      product: 'curtain-shower',
      variations: 'Douchegordijn,Douchegordijnen,Badkamergordijn,Douchegodrn,Douchegrdn,Gordjin,Doucch Grrd,Keukenkliederen',
    },
    {
      product: 'towel-tea',
      variations: 'Theedoek,Theedoeken,Keukenhanddoek,Keukenlap,Thdok,Theedok,Thedok,Thdoek,Thedook,Badtokken',
    },
    {
      product: 'towel-bath',
      variations: 'Handdoek,Handdoeken,Badhanddoek,Handdok,Handoek,Badhandk,Badhdoke,Zonelakens',
    },
    {
      product: 'towel-beach',
      variations: 'Strandlaken,Strandlakens,Zonnehanddoek,Badlaken,Strandlakn,Strandlk,Strandlkn,Strnd tken,Badlkn,Knuffelekens',
    },
    {
      product: 'blanket-fleece',
      variations: 'Fleece deken,Fleece dekens,Knuffeldeken,Slaapdeken,Flec Deken,Flecede,Flcdeken,Deken polair,Warme deken,Entracematjes',
    },
    {
      product: 'doormat',
      variations: 'Deurmat,Deurmatten,Voetmat,Ingangsmat,Vloermat,Durmat,Doormat,Ingangs mat,Voetmat,Shrffboekjes',
    },
    {
      product: 'notebook',
      variations: 'Notitieboek,Notitieboeken,Dagboek,Schrijfboek,Journal,Notbk,Ntboek,Notieboek,Schriftje,Schrvboek,Stkrs',
    },
    {
      product: 'sticker',
      variations: 'Sticker,Stickers,Plakker,Klever,Stickr,Sikker,Stickrr,Plkr,Mobiehoesjes',
    },
    {
      product: 'phone-cover-iphone',
      variations: 'Telefoonhoesje,Telefoonhoesjes,Mobiel hoesje,GSM-hoesje,Telh,Telhnhoesje,Telefoonhseje,Tefnhosje,Hoes Telfon,Muurknsten',
    },
    {
      product: 'poster',
      variations: 'Poster,Posters,Afdruk,Muurkunst,Kunstwerk,Pster,Pooster,Postr,Afdrk,Wlkst,Dkeafdrukken',
    },
    {
      product: 'leinwand',
      variations: 'Canvas,Canvassen,Wandkunst,Doekafdruk,Kunst op doek,Canvs,Canavs,Cnvas,Doekafrk,Kunstdoek,Fotmrppapieren',
    },
    {
      product: 'wallpaper',
      variations: 'Fotobehang,Fotobehangen,Muurpapier,Fotomuur,Ftbhng,Fotbehang,Fotbhng,Wllpaper,Ftobhang',
    },
    {
      product: 'wall-art-framed',
      variations: 'Fotolijstje,Kader,fotolijsten,fotonamen,fotolijsten',
    },
  ],
  uk: [
    {
      product: 't-shirt',
      variations: 'T-Shirt,T-Shirts,Tee,Top,Short Sleeve,Tshrt,TShirt,T-shrit,T-shrt,Shir,Shrt,Tsirt,Ttops,Shoerts,T-shorts',
    },
    {
      product: 'hoodie',
      variations: 'Hoodie,Hoodies,Hooded Sweater,Pullover,Hoody,Hoddie,Hoodi,Hudie,Hoodd Sweater,Hoded Sweater,Hoded Sweaters,Poolvers',
    },
    {
      product: 'sweatshirt',
      variations: 'Sweatshirt,Sweatshirts,Pullover,Sweater,Swetshirt,Swatshirt,Swater,Pullver,Sweatr,Pulovrs,Swters',
    },
    {
      product: 't-shirt-kid',
      variations: 'Kids T-Shirt,Kids T-Shirts,Children’s Shirt,Kids Tee,Child T-Shirt,Kids Tshrt,Kids T-shrit,Kid T-Shirt,Childern Shirt,Kds Tee,Chld Ts,Kd Tees',
    },
    {
      product: 'hoodie-kid',
      variations: 'Kids Hoodie,Kids Hoodies,Children’s Hoodie,Kids Pullover,Kids Hoody,Kids Hoodi,Kid Hoodie,Hoded Pullover,Hoody Kid,Hoddies,Chld Hoodies',
    },
    {
      product: 'sweatshirt-kid',
      variations: 'Kids Sweatshirt,Kids Sweatshirts,Children’s Sweater,Kids Pullover,Kids Swetshirt,Kid Sweatshirt,Swater,Kids Sweatr,Children Pulover,Swaters,Chld Sweats',
    },
    {
      product: 'romper-short-sleeve-baby',
      variations: 'Bodysuit,Bodysuits,Baby Bodysuit,Onesie,Boddy,Bodi,Badysuit,Bbysuit,Onesy,Bodysuts,Onsies',
    },
    {
      product: 'rompoer-long-sleeve-baby',
      variations: 'Romper,Rompers,Baby Romper,Infant Romper,Rompr,Rompr,Rmper,Rompper,Infnt Romper,Infnt Rompers,Bby Romprs',
    },
    {
      product: 'bib-baby',
      variations: 'Bib,Bibs,Baby Bib,Feeding Bib,Bibb,Bby Bib,Feading Bib,Beab Bib,Fedings,Bb Bibs',
    },
    {
      product: 't-shirt-baby',
      variations: 'Baby T-Shirt,Baby T-Shirts,Baby Tee,Infant Shirt,Baby Tshrt,Baby T-Shrit,Infant Tsr,Tee Babe,Infnt Shirts,Bb Tees',
    },
    {
      product: 'sweatshirt-baby',
      variations: 'Baby Sweatshirt,Baby Sweatshirts,Baby Sweater,Infant Pullover,Baby Swetshirt,Baby Swatshirt,Baby Pulvr,Infnt Pullover,Infnt Pulovers',
    },
    {
      product: 'bag-tote',
      variations: 'Tote Bag,Tote Bags,Shopping Bag,Reusable Bag,Canvas Bag,Tote Bg,Totebag,Tte Bag,Shoping Bag,Cnvas Bag,Shoping Bags,Reusble Bgs',
    },
    {
      product: 'mug-ceramic',
      variations: 'Mug,Mugs,Cup,Coffee Mug,Tea Cup,Mugg,Mog,Muug,Cofee Mug,Tea Cuo,Coffe Mugs,Tee Cups',
    },
    {
      product: 'cushion-decorative',
      variations: 'Cushion,Cushions,Pillow,Sofa Cushion,Decorative Pillow,Cushon,Cusion,Cuishion,Decortive Pillow,Sfa Cushion,Decortive Pillows,Cshions',
    },
    {
      product: 'curtain-shower',
      variations: 'Shower Curtain,Shower Curtains,Bathroom Curtain,Bath Curtain,Showr Curtain,Showercurtan,Shwr Curtain,Bathrom Curtain,Bath Curten,Bathrom Curtains',
    },
    {
      product: 'towel-tea',
      variations: 'Tea Towel,Tea Towels,Dish Towel,Kitchen Towel,T Towell,T Towel,Tetowel,Dis Towel,Kitchn Towel,Dish Towells,Ktchen Towels',
    },
    {
      product: 'towel-bath',
      variations: 'Hand Towel,Hand Towels,Bath Towel,Bathroom Towel,Hand Towl,Hand Towle,Hndtowel,Bthroom Towel,Bth Towle,Bathrom Towels,Bd Towels',
    },
    {
      product: 'towel-beach',
      variations: 'Beach Towel,Beach Towels,Pool Towel,Sun Towel,Beachtowel,Beach Towel,Bch Towel,Pool Twl,Sun Twl,Pool Towells',
    },
    {
      product: 'blanket-fleece',
      variations: 'Fleece Blanket,Fleece Blankets,Throw Blanket,Cozy Blanket,Flec Blanket,Flees Blanket,Fleece Blankt,Thrw Blanket,Cozy Blankt,Thro Blankets,Cozy Blanets',
    },
    {
      product: 'doormat',
      variations: 'Doormat,Doormats,Welcome Mat,Entrance Mat,Floor Mat,Dormat,Dormate,Doormatte,Entrnce Mat,Floore Mat,Welcom Mats,Entrnce Mats',
    },
    {
      product: 'notebook',
      variations: 'Notebook,Notebooks,Journal,Writing Book,Diary,Notebk,Ntbook,Ntebook,Jurnal,Writing Bok,Wirting Books,Dirays',
    },
    {
      product: 'sticker',
      variations: 'Sticker,Stickers,Decal,Label,Stickr,Stiker,Stickar,Dcal,Labelle,Dcals,Lables',
    },
    {
      product: 'phone-cover-iphone',
      variations: 'Phone Case,Phone Cases,Cellphone Cover,Mobile Case,Phne Case,Phonecase,Ponecase,Moble Cover,Cellfone Case,Celphone Covers',
    },
    {
      product: 'poster',
      variations: 'Poster,Posters,Print,Wall Art,Artwork,Billboard,Postr,Poaster,Pster,Prnt,Wall Rt,Wallarts,Arworks,Billbord',
    },
    {
      product: 'leinwand',
      variations: 'Canvas,Canvases,Wall Art,Canvas Print,Art Print,Canvse,Canas,Cannvas,Wall Rt,Cnvas Pint,Canvas Pints,Art Pints',
    },
    {
      product: 'wallpaper',
      variations: 'Wallpaper,Wallpapers,Mural,Wall Art,Photowall,Walpaper,Wallpper,Wllpaper,Murul,Wll Art,Wallppars,Muralls',
    },
    {
      product: 'wall-art-framed',
      variations: 'Picture frame,Frame,picture frames,picture names,picture frames',
    },
  ],
};

<script setup lang="ts">
import { type HeaderIconLinkTransformed } from '@/types/storyblok';

withDefaults(defineProps<{
  icon: Component;
  isPhoneNumber?: boolean;
  link?: HeaderIconLinkTransformed;
}>(), { isPhoneNumber: false, })

const { getStoryblokUrl } = useStoryblokData();

const getLink = () => {
  if (__props.link) {
    return __props.isPhoneNumber
      ? `tel:${__props.link.title}`
      : getStoryblokUrl(__props.link.link);
  }
  return '';
};
</script>

<template>
  <NuxtLinkLocale
    class="icon-link flex items-center text-grey-semidark hover:text-grey-dark"
    :to="getLink()"
  >
    <span class="flex items-center">
      <component
        :is="icon"
        class="mr-2 size-4"
      />
      <span
        class="whitespace-nowrap text-xs"
        v-text="link?.title || ''"
      />
    </span>
  </NuxtLinkLocale>
</template>

<style lang="scss">
.icon-link {
  .image {
    filter: invert(47%) sepia(10%) saturate(1031%) hue-rotate(176deg) brightness(91%) contrast(82%);
  }

  &:hover {
    .image {
      filter: invert(32%) sepia(7%) saturate(1280%) hue-rotate(176deg) brightness(92%) contrast(98%);
    }
  }
}
</style>

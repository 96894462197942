<script setup lang="ts">
import type { DefaultLayoutQuery } from '#gql';

defineProps<{
  footer: DefaultLayoutQuery['footer'];
  trustpilotInformation?: DefaultLayoutQuery['trustpilotInformation'];
  trustpilotReviews?: DefaultLayoutQuery['trustpilotReviews'];
}>();

const copyright = computed(() => `© ${new Date().getFullYear()} JUNIQE`);
</script>

<template>
  <footer class="w-full max-w-full overflow-x-hidden">
    <NewsletterSignup
      v-if="footer.newsletterSignup"
      :blok="footer.newsletterSignup"
    />
    <ContactInformation
      v-if="footer.contactBlocks"
      :contact-blocks="footer.contactBlocks"
    />
    <TrustpilotSection
      v-if="trustpilotInformation && trustpilotReviews?.length"
      :reviews="trustpilotReviews"
      :total-reviews="trustpilotInformation.totalReviews"
      :trust-score="trustpilotInformation.trustScore"
    />
    <PaymentMethods
      v-if="footer.paymentMethods"
      :payment-methods="footer.paymentMethods"
    />
    <FooterLinks
      :link-columns="footer.linkColumns"
      :social-links="footer.socialLinks"
      :text="footer.text"
    />

    <div class="bg-purple py-4 text-xs text-white">
      <div class="container flex max-w-7xl flex-col justify-between gap-4 md:flex-row">
        <div>
          {{ copyright }}
        </div>

        <div>{{ footer.footnote }}</div>
      </div>
    </div>
  </footer>
</template>

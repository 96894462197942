<script setup lang="ts">
import {
  ArrowLeftIcon,
  SendIcon,
  UserCircleIcon,
} from 'lucide-vue-next';
import { passwordValidationSchema } from 'shared-components';
import { Logger } from 'utils';
import {
  object,
  string,
} from 'yup';

const { t } = useI18n();
const {
  closeDialog,
  setView,
} = useAuthDialog();
const {
  fetchCustomer,
  signup,
} = useCustomer();
const tabsValue = ref('customer');
const submitted = ref(false);

watch(tabsValue, (newValue) => {
  if (newValue === 'creator') {
    window.open(new URL('/creator-suite/registration', window.location.origin), '_blank');
    tabsValue.value = 'customer';
    closeDialog();
  }
});

const serverError = ref<string | null>(null);
const {
  handleSubmit,
  isFieldTouched,
  isSubmitting,
  values,
} = useForm({
  validationSchema: toTypedSchema(object({
    email: string().transform((value) => value.trim().toLowerCase())
      .required(t('form.errorMessage.required'))
      .email(t('form.errorMessage.email')),
    firstname: string().transform((value) => value.trim())
      .required(t('form.errorMessage.required')),
    lastname: string().transform((value) => value.trim())
      .required(t('form.errorMessage.required')),
    password: passwordValidationSchema,
  })),
});

const onSubmit = handleSubmit(async (formValues) => {
  serverError.value = null;

  try {
    await signup(formValues);
    await fetchCustomer();
    closeDialog();
  } catch (e) {
    if (e instanceof Error) {
      serverError.value = e.message;
    }

    Logger.error('Could not sign up', e);
  }
});
</script>

<template>
  <form @submit.prevent="onSubmit()">
    <div
      class="relative flex flex-col items-center justify-center bg-white p-8 text-center"
      :class="[submitted ? 'pb-4' : 'pb-12']"
    >
      <Button
        class="absolute left-4 top-4 size-10 rounded-full border border-grey-light p-0 text-dark shadow-none hover:!border-grey-mid focus-visible:ring-1"
        variant="outline"
        @click="setView('login')"
      >
        <ArrowLeftIcon class="size-6" />
      </Button>

      <template v-if="submitted">
        <SendIcon class="mb-2 size-10" />
        <h2 class="my-0 text-xl font-bold">
          {{ $t('authDialog.registration.success.headline') }}
        </h2>
        <p class="mt-1 font-medium">
          {{ $t('authDialog.registration.success.description') }}
        </p>
      </template>
      <template v-else>
        <UserCircleIcon class="mb-2 size-10" />
        <h2 class="my-0 text-xl font-bold">
          {{ $t('authDialog.registration.headline') }}
        </h2>
        <p class="mb-0 mt-1 font-medium">
          {{ $t('authDialog.registration.description') }}
        </p>
      </template>
    </div>
    <div
      class="relative p-8"
      style="background-image: linear-gradient(to bottom, var(--color-grey-light) 0, var(--color-white) 1rem)"
    >
      <template v-if="submitted">
        <Button
          class="w-full"
          type="button"
          variant="info"
          @click="setView('login')"
        >
          {{ $t('authDialog.registration.success.submit') }}
        </Button>
      </template>
      <template v-else>
        <Tabs
          class="-mx-1 -mt-14 mb-8"
          :model-value="tabsValue"
          @update:model-value="(newValue) => tabsValue = String(newValue)"
        >
          <TabsList class="w-full border-4 border-white">
            <TabsTrigger value="customer">
              {{ $t('authDialog.customer') }}
            </TabsTrigger>
            <TabsTrigger value="creator">
              {{ $t('authDialog.creator') }}
            </TabsTrigger>
          </TabsList>
        </Tabs>

        <div class="grid grid-cols-1 gap-5">
          <UiAlert
            v-if="serverError"
            type="error"
            :body="serverError"
          />
          <FormField
            v-slot="{ componentField }"
            name="firstname"
          >
            <FormItem>
              <FormLabel :label="$t('authDialog.registration.firstname')" />
              <FormControl>
                <Input
                  :id="componentField.name"
                  v-bind="componentField"
                  autocomplete="firstname"
                />
                <FormMessage />
              </FormControl>
            </FormItem>
          </FormField>

          <FormField
            v-slot="{ componentField }"
            name="lastname"
          >
            <FormItem>
              <FormLabel :label="$t('authDialog.registration.lastname')" />
              <FormControl>
                <Input
                  :id="componentField.name"
                  v-bind="componentField"
                  autocomplete="lastname"
                />
                <FormMessage />
              </FormControl>
            </FormItem>
          </FormField>

          <FormField
            v-slot="{ componentField }"
            name="email"
          >
            <FormItem>
              <FormLabel :label="$t('authDialog.registration.email')" />
              <FormControl>
                <Input
                  :id="componentField.name"
                  v-bind="componentField"
                  autocomplete="email"
                />
                <FormMessage />
              </FormControl>
            </FormItem>
          </FormField>

          <FormField
            v-slot="{ componentField }"
            name="password"
          >
            <FormItem>
              <FormLabel :label="$t('authDialog.registration.password')" />
              <FormControl>
                <PasswordInput
                  :id="componentField.name"
                  v-bind="componentField"
                  autocomplete="new-password"
                />

                <PasswordValidityCheck
                  class="mt-2"
                  :field-touched="isFieldTouched('password')"
                  :value="values.password ?? ''"
                />
              </FormControl>
            </FormItem>
          </FormField>
        </div>
      </template>
    </div>

    <div
      v-if="!submitted"
      class="relative p-6"
      style="background-image: linear-gradient(to bottom, var(--color-grey-light) 0, var(--color-white) 1rem)"
    >
      <Button
        class="w-full"
        type="submit"
        variant="cta"
        :disabled="isSubmitting"
        :is-loading="isSubmitting"
      >
        {{ $t('authDialog.registration.submit') }}
      </Button>
    </div>
  </form>
</template>

<script setup lang="ts">
const {
  activeView,
  closeDialog,
  isDialogOpen,
  openDialog,
} = useAuthDialog();

const searchParams = useUrlSearchParams();

const viewComponent = computed(() => {
  switch (activeView.value) {
    case 'registration':
      return resolveComponent('RegistrationForm');

    case 'forgot-password':
      return resolveComponent('ForgotPasswordForm');

    default:
      return resolveComponent('LoginForm');
  }
});

onMounted(() => {
  if (searchParams.authView) {
    openDialog(searchParams.authView as AuthDialogView);
  }
});
</script>

<template>
  <DialogRoot
    :open="isDialogOpen"
    @update:open="open => !open && closeDialog()"
  >
    <DialogContent class="max-w-md bg-grey-extra-light p-0 outline-none">
      <component :is="viewComponent" />
    </DialogContent>
  </DialogRoot>
</template>

<script setup lang="ts">
import {
  ArrowRightIcon,
  DoorOpenIcon,
} from 'lucide-vue-next';
import { LocalStorageKey } from 'shared-components';

withDefaults(defineProps<{
  buttonLinkCreator?: string;
  buttonLinkStoryblok: string;
  buttonText: string;
  buttonTextLoggedIn?: string | null;
  fullWidth?: boolean;
}>(), { fullWidth: false, })

const creatorImage = useLocalStorage<string>(LocalStorageKey.CreatorImage, '');

const translationKeys = { creatorLogin: 'common.creatorLogin' };

const onClick = async (url?: string) => {
  if (url) {
    await navigateTo(url, { open: { target: '_blank' } });
  }
};
</script>

<template>
  <div class="relative z-10 h-10 min-w-[166px] rounded-3xl bg-purple">
    <ClientOnly>
      <div class="relative z-20 flex items-center">
        <button
          class="group/cta inline-flex h-10 min-w-[125px] items-center space-x-1.5 rounded-l-3xl bg-purple p-3 text-xs text-white hover:bg-purple-dark"
          type="button"
          :class="{ '!min-w-[166px] rounded-3xl !p-[5px]': !!creatorImage || !buttonLinkCreator, 'w-full justify-center': fullWidth }"
          @click="onClick(!!(buttonLinkStoryblok && !creatorImage || fullWidth) ? buttonLinkStoryblok : buttonLinkCreator)"
        >
          <span
            v-if="creatorImage && buttonLinkCreator"
            class="absolute z-10"
            :class="{ 'mr-28': fullWidth }"
          >
            <NuxtResponsivePicture
              class="rounded-full"
              provider="cloudflare"
              :src="creatorImage"
              :width="28"
            />
          </span>
          <span
            :class="cn('flex items-center justify-center size-5 rounded-full bg-white text-purple transition-transform group-hover/cta:translate-x-1', {
              'z-10 rounded-full border-2 border-purple group-hover/cta:border-purple-dark md:!ml-[22px]': !!creatorImage,
              'md:!ml-0': !buttonLinkCreator
            })"
          >
            <ArrowRightIcon class="size-4" />
          </span>
          <span v-text="!!creatorImage && buttonLinkCreator && buttonTextLoggedIn || buttonText" />
        </button>

        <span
          v-if="buttonLinkCreator && !creatorImage"
          class="hidden h-10 w-px bg-white md:flex"
        />

        <Tooltip v-if="!creatorImage && buttonLinkCreator">
          <template #trigger>
            <button
              class="group/cta hidden h-10 items-center rounded-r-3xl bg-purple p-3 pl-2 text-xs text-white hover:bg-purple-dark md:inline-flex"
              type="button"
              @click="onClick(buttonLinkCreator)"
            >
              <DoorOpenIcon class="size-5" />
            </button>
          </template>
          <template #content>
            <div v-t="translationKeys.creatorLogin" />
          </template>
        </Tooltip>
      </div>
    </ClientOnly>
  </div>
</template>

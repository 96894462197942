<script lang="ts" setup>
import { toValue } from 'vue';
import { useFormField } from './useFormField';

const {
  formMessageId,
  name,
} = useFormField();
</script>

<template>
  <ErrorMessage
    :id="formMessageId"
    v-slot="{message}"
    as="p"
    class="mb-0 mt-1 text-xs text-red"
    :name="toValue(name)"
  >
    {{ $te(message!) ? $t(message!) : message }}
  </ErrorMessage>
</template>

<script lang="ts" setup>
import {
  CheckCircleIcon,
  XCircleIcon,
} from 'lucide-vue-next';
import { type ValidationError } from 'yup';
import { passwordValidationSchema } from '../../lib/passwords';

withDefaults(defineProps<{
  fieldTouched?: boolean;
  value: string;
}>(), {  })

const checks = computed(() => {
  let errors: string[] = [];

  try {
    passwordValidationSchema.validateSync(__props.value, { abortEarly: false });
  } catch (error) {
    errors = (error as ValidationError).errors;
  }

  return [
    {
      translationKey: 'passwordValidity.minLength',
      valid: !errors.includes('hasMinEightCharacters'),
    },
    {
      translationKey: 'passwordValidity.oneUpperAndLowerCase',
      valid: !(errors.includes('hasUpperCase') || errors.includes('hasLowerCase')),
    },
    {
      translationKey: 'passwordValidity.oneNumber',
      valid: !errors.includes('hasDigit'),
    },
    {
      translationKey: 'passwordValidity.oneSymbol',
      valid: !errors.includes('hasSymbol'),
    },
  ];
});
</script>

<template>
  <div class="grid gap-x-4 gap-y-1 sm:grid-cols-2">
    <div
      v-for="check in checks"
      :key="check.translationKey"
      class="transition-color flex items-center text-xs duration-150"
      :class="[check.valid ? 'text-green' : (fieldTouched ? 'text-red' : 'text-dark/30')]"
    >
      <component
        :is="check.valid ? CheckCircleIcon : XCircleIcon"
        class="mr-1 size-4"
      />
      {{ $t(check.translationKey) }}
    </div>
  </div>
</template>

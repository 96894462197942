<script lang="ts" setup>
import { useFormField } from './useFormField';
import type { LabelProps } from 'radix-vue';
import type { HTMLAttributes } from 'vue';

const props = defineProps<LabelProps & {
  class?: HTMLAttributes['class'];
  label: string;
  optionalText?: string;
  required?: boolean;
}>();

const {
  error,
  formItemId,
} = useFormField();
</script>

<template>
  <Label
    :class="cn(
      'flex items-center gap-x-3 flex-wrap sm:mt-2',
      error && 'text-red-500 dark:text-red-900',
      props.class,
    )"
    :for="formItemId"
  >
    <span class="font-medium">
      {{ label }}
    </span>
    <span
      v-if="props.required"
      class="-ml-2 text-grey-semidark"
    >
      <!-- eslint-disable-line @intlify/vue-i18n/no-raw-text -->
      *
    </span>
    <span
      v-if="optionalText"
      class="text-xs font-light leading-tight text-grey-semidark"
    >{{ optionalText }}</span>
    <slot />
  </Label>
</template>

<script setup lang="ts">
import {
  TabsList,
  type TabsListProps,
} from 'radix-vue';
import { type HTMLAttributes } from 'vue';

const props = defineProps<TabsListProps & { class?: HTMLAttributes['class'] }>();

const delegatedProps = computed(() => {
  const {
    class: _,
    ...delegated
  } = props;

  return delegated;
});
</script>

<template>
  <TabsList
    v-bind="delegatedProps"
    :class="cn(
      'inline-flex items-center justify-center rounded-full bg-purple-light p-1 text-purple',
      props.class,
    )"
  >
    <slot />
  </TabsList>
</template>

<script setup lang="ts">
import {
  AlertCircleIcon,
  AlertTriangleIcon,
  CheckCircleIcon,
} from 'lucide-vue-next';

type AlertType = 'error' | 'success' | 'warn';

withDefaults(defineProps<{
  body?: string;
  buttonText?: string;
  title?: string;
  type: AlertType;
}>(), {  })

defineEmits<{
  buttonClicked: [];
}>();

const iconForAlertType = computed(() => ({
  error: AlertCircleIcon,
  success: CheckCircleIcon,
  warn: AlertTriangleIcon,
})[__props.type]);
</script>

<template>
  <div
    class="alert-component flex flex-col items-center rounded-xl border p-5 md:flex-row"
    :class="{
      'bg-green-light text-dark': type === 'success',
      'border-red bg-red/5 text-red': type === 'error',
      'border-orange bg-orange/10 text-orange': type === 'warn'
    }"
  >
    <div
      class="hidden size-10 shrink-0 items-center justify-center rounded-md md:flex"
      :class="{
        'bg-green-dark' : type === 'success',
        'bg-red' : type === 'error',
        'bg-orange' : type === 'warn'
      }"
    >
      <component
        :is="iconForAlertType"
        aria-hidden="true"
        class="size-5 text-white"
      />
    </div>
    <div class="ml-3">
      <h3
        v-if="title"
        class="font-medium"
        v-text="title"
      />
      <p
        v-if="body"
        class="m-0"
        v-text="body"
      />
    </div>
    <Button
      v-if="buttonText"
      class="mt-4 min-w-[92px] shrink-0 md:ml-auto md:mt-0"
      variant="primary"
      @click="$emit('buttonClicked')"
    >
      {{ buttonText }}
    </Button>
  </div>
</template>

import { useStorage } from '@vueuse/core';
import { LocalStorageKey } from 'shared-components';

type CreatorPayload = {
  expiry: string | null;
  publicId: string | null;
  token: string | null;
};

const useCreator = () => {
  const { BASE_URL } = useBaseUrl();

  const creator = useStorage<CreatorPayload>(LocalStorageKey.CreatorCredentials, {
    expiry: null,
    publicId: null,
    token: null,
  });

  const creatorSuiteBackendUrl = computed(() => `${BASE_URL}/creator-admin-backend/api/v1`);

  const login = async (payload: {
    email: string;
    password: string;
  }) => {
    const response = await fetch(`${creatorSuiteBackendUrl.value}/authorize`, {
      body: JSON.stringify(payload),
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    });

    if (!response.ok) {
      throw new Error('Signup failed');
    }

    const creatorPayload = await response.json();

    creator.value = creatorPayload;
  };

  return {
    creator,
    login,
  };
};

export { useCreator };

<script setup lang="ts">
import {
  getTrustpilotLanguageFromLocaleCode,
  type LocaleCode,
} from 'localization';
import TrustpilotLogo from './TrustpilotLogo.vue';
import type { DefaultLayoutQuery } from '#gql';

defineProps<{
  reviews: DefaultLayoutQuery['trustpilotReviews'];
  totalReviews: number;
  trustScore: number;
}>();

const { locale } = useI18n();

const { formatTimeDistance } = useFormatter();

const formatDate = (date: string) => formatTimeDistance(new Date(date), new Date());

const trustpilotLocale = getTrustpilotLanguageFromLocaleCode(locale.value as LocaleCode);
const trustpilotLink = `https://${trustpilotLocale === 'en'
  ? 'uk'
  : trustpilotLocale}.trustpilot.com/review/juniqe.com`;
</script>

<template>
  <div class="container mb-12 max-w-7xl">
    <h2 class="m-0 block text-sm text-semidark">
      {{ $t('footer.trustpilot.subline') }}
    </h2>
    <p class="mb-4 mt-0 text-2xl font-black text-purple md:text-3xl">
      {{ $t('footer.trustpilot.headline') }}
    </p>
    <Carousel>
      <div
        v-for="(review, i) of reviews"
        :key="i"
        class="flex w-full shrink-0 flex-col justify-between gap-4 rounded-2xl bg-grey-light p-6 md:w-[calc(100%/2-0.625rem)] lg:w-[calc(100%/3-0.625rem)]"
      >
        <div class="flex h-full flex-col justify-between gap-4">
          <div>
            <TrustpilotRating
              class="h-auto w-28"
              :rating="review.rating"
            />
            <h4 class="mb-2 mt-4 truncate text-base font-semibold text-dark">
              {{ review.title }}
            </h4>
            <div class="line-clamp-4 whitespace-pre-line text-base">
              <FormattedMarkdown
                v-if="review.content"
                :content="review.content"
              />
            </div>
          </div>

          <div class="flex flex-wrap justify-between gap-x-4 gap-y-2 text-sm text-grey-semidark">
            <span class="font-semibold">{{ review.name }}</span>
            <span
              v-if="review.date"
              class="font-normal"
            >{{ formatDate(review.date) }}</span>
          </div>
        </div>
      </div>
    </Carousel>

    <a
      class="mt-6 flex items-center justify-end gap-4"
      target="_blank"
      :href="trustpilotLink"
    >
      <TrustpilotRating
        class="h-auto w-28"
        :rating="trustScore"
      />
      <i18n-t
        class="m-0 text-sm leading-tight text-grey-semidark"
        keypath="footer.trustpilot.footnote"
        tag="span"
      >
        <template #trustScore>
          <strong>{{ $t('footer.trustpilot.trustScore', { trustScore }) }}</strong>
        </template>
        <template #totalReviews>
          <strong>{{ $t('footer.trustpilot.totalReviews', { totalReviews }) }}</strong>
        </template>
      </i18n-t>
      <TrustpilotLogo class="-mt-1 h-auto w-24" />
    </a>
  </div>
</template>

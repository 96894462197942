<script setup lang="ts">
import {
  HeartIcon,
  MenuIcon,
  ShoppingCartIcon,
} from 'lucide-vue-next';

import { HeaderLinks } from '@/config/header-links';

const { customer } = useCustomer();

const { setIsBurgerNavigationVisible } = useBurgerNavigation();
</script>

<template>
  <div
    class="header-mobile mb-2.5 mt-3 flex w-full flex-col px-4"
    data-cy="header-mobile"
  >
    <div class="flex w-full items-center justify-between">
      <div class="flex gap-x-2.5">
        <UserButton />
        <IconButton
          icon-orientation="left"
          :button-link="HeaderLinks.Wishlist"
          :icon="HeartIcon"
        />
      </div>
      <div class="flex flex-1 justify-center">
        <LogoComponent />
      </div>
      <div class="flex justify-end">
        <div
          class="flex"
          :class="[!!customer?.cartArticlesAmount ? '-mr-1.5' : 'mr-3.5']"
        >
          <IconButton
            is-link-external
            icon-orientation="right"
            :badge-info="customer?.cartArticlesAmount"
            :button-link="HeaderLinks.Cart"
            :icon="ShoppingCartIcon"
          />
        </div>
        <button
          class="relative flex h-8 w-7 items-center justify-end bg-white md:w-10 md:justify-center"
          type="button"
        >
          <MenuIcon
            class="size-7 text-dark"
            @click="setIsBurgerNavigationVisible(true)"
          />
        </button>
      </div>
    </div>
    <div class="header-search z-60 relative mr-4 mt-3.5 w-full grow">
      <SearchInput :is-desktop-header="false" />
    </div>
  </div>
</template>

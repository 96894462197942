<script setup lang="ts">
import { getQuery } from 'ufo';
import type { DefaultLayoutQuery } from '#gql';

defineProps<{
  linkColumns: DefaultLayoutQuery['footer']['linkColumns'];
  socialLinks: DefaultLayoutQuery['footer']['socialLinks'];
  text?: string | null;
}>();

const {
  getIsMatureContentEnabled,
  setIsMatureContentEnabled,
} = useMatureContent();

const { getStoryblokUrl } = useStoryblokData();

const translationKeys = {
  adultContentNotVisible: 'footer.adultContent.notVisible',
  adultContentTitle: 'footer.adultContent.title',
  adultContentVisible: 'footer.adultContent.visible',
  socialLinks: 'footer.socialLinks',
};

const matureContent = computed({
  get() {
    return getIsMatureContentEnabled.value;
  },
  set(matureContentValue) {
    setIsMatureContentEnabled(matureContentValue);
  },
});

const bindUCOverlay = (link: string) => (getQuery(link).open === 'ucSettings'
  ? {
    onClick: (event: Event) => {
      event.preventDefault();
      // @ts-expect-error This is provided globally by the Usercentrics script
      window.__ucCmp.showSecondLayer();
    },
  }
  : {});
</script>

<template>
  <div class="bg-semidark py-12 text-sm text-white">
    <div class="container max-w-7xl">
      <div class="grid grid-cols-1 gap-4 md:grid-cols-3">
        <div>
          <h4
            class="mb-3 text-base font-bold"
            v-text="$t(translationKeys.socialLinks)"
          />
          <ul class="m-0 flex list-none gap-3">
            <li
              v-for="(socialLink, i) of socialLinks"
              :key="i"
            >
              <a
                class="block size-8 opacity-80 hover:opacity-100"
                target="_blank"
                :href="socialLink.link"
                :title="socialLink.title"
              >
                <NuxtResponsivePicture
                  v-if="socialLink.icon"
                  aspect-ratio="1/1"
                  background-color-placeholder="transparent"
                  provider="storyblok"
                  :alt="socialLink.title"
                  :src="socialLink.icon"
                  :width="32"
                />
              </a>
            </li>
          </ul>
        </div>

        <div>
          <h4
            class="mb-3 text-base font-bold"
            v-text="$t(translationKeys.adultContentTitle)"
          />
          <div class="flex items-center gap-2">
            <Switch
              id="matureContentSwitch"
              v-model:checked="matureContent"
              class="data-[state=unchecked]:border-dark data-[state=unchecked]:bg-dark"
            />
            <label
              class="cursor-pointer text-xs"
              for="matureContentSwitch"
              v-text="$t(matureContent ? translationKeys.adultContentVisible : translationKeys.adultContentNotVisible)"
            />
          </div>
        </div>
      </div>
      <div class="mt-8 grid grid-cols-1 gap-4 md:grid-cols-3">
        <div
          v-for="(column, i) of linkColumns"
          :key="i"
        >
          <div
            v-for="(block, k) of column.blocks"
            :key="k"
          >
            <h4
              class="mb-3 text-base font-bold"
              v-text="block.title"
            />
            <ul
              v-if="block.items?.length"
              class="m-0 list-none space-y-2"
            >
              <li
                v-for="(item, j) of block.items"
                :key="j"
              >
                <a
                  class="text-white/80 hover:text-white"
                  :href="getStoryblokUrl(item.link)"
                  v-bind="bindUCOverlay(getStoryblokUrl(item.link))"
                >
                  {{ item.title }}
                </a>
              </li>
            </ul>
            <div
              v-if="block.content"
              class="whitespace-pre-line text-white/80  [&>span>p]:last-of-type:mb-0"
            >
              <FormattedMarkdown
                allow-html
                allow-text-formatting
                :content="block.content"
                :inline="false"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="text"
        class="v mt-6 whitespace-pre-line text-xs text-white/80 [&>span>p]:mb-0 [&>span>p]:leading-relaxed"
      >
        <FormattedMarkdown
          allow-html
          allow-text-formatting
          :content="text"
          :inline="false"
        >
        </formattedmarkdown>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { DefaultLayoutQuery } from '#gql';

defineProps<{
  contactBlocks: NonNullable<DefaultLayoutQuery['footer']['contactBlocks']>;
}>();

const { getStoryblokUrl } = useStoryblokData();
</script>

<template>
  <div class="container max-w-7xl py-12">
    <div class="grid gap-8 lg:grid-cols-3">
      <div
        v-for="(block, i) of contactBlocks"
        :key="i"
        class="flex flex-col justify-between gap-4 rounded-2xl border border-purple/20 p-6"
      >
        <div>
          <h4 class="mb-4 text-lg font-black text-purple">
            {{ block.title }}
          </h4>
          <div class="whitespace-pre-line text-base">
            <FormattedMarkdown
              v-if="block.content"
              :content="block.content"
            />
          </div>
        </div>

        <Button
          as-child
          class="w-full border-transparent"
          variant="primary"
        >
          <NuxtLink :to="getStoryblokUrl(block.buttonLink)">
            {{ block.buttonText }}
          </NuxtLink>
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
withDefaults(defineProps<{
  badgeInfo?: number | null;
  buttonLink?: string;
  icon: Component;
  iconOrientation?: string;
  imageInfo?: string;
  isLinkExternal?: boolean;
  withBorder?: boolean;
}>(), { badgeInfo: null,buttonLink: '',imageInfo: null,isLinkExternal: false,withBorder: false, })
defineEmits([ 'click' ]);

const localePath = useLocalePath();

const onClick = async () => {
  if (!__props.buttonLink) {
    return;
  }

  await navigateTo(localePath(__props.buttonLink), { external: __props.isLinkExternal });
};

const iconClasses = computed(() => ([
  { 'justify-start': __props.iconOrientation === 'left' },
  { 'justify-end': __props.iconOrientation === 'right' },
  { 'justify-center': !__props.iconOrientation },
  { 'rounded-full border border-grey transition duration-300 ease-in-out hover:border-2 hover:border-dark': __props.withBorder },
]));
</script>

<template>
  <button
    class="flex size-8 items-center bg-white md:ml-4 md:size-10"
    type="button"
    :class="iconClasses"
    @click="onClick(); $emit('click')"
  >
    <div
      v-if="imageInfo"
      class="flex size-6 items-center justify-center rounded-full bg-dark"
    >
      <span
        class="text-sm font-bold uppercase text-white"
        v-text="imageInfo.charAt(0)"
      />
    </div>
    <component
      :is="icon"
      v-else
      class="size-6 text-dark"
    />
  </button>
  <span
    v-if="badgeInfo !== null"
    class="relative flex size-5 items-center justify-center rounded-full border bg-green-dark p-1 text-xs font-bold text-white"
    :class="[withBorder ? '-top-1 right-3.5' : '-top-1.5 right-3']"
    v-text="badgeInfo"
  />
</template>

<script lang="ts" setup>
import { Slot } from 'radix-vue';
import { useFormField } from './useFormField';

const {
  error,
  formDescriptionId,
  formItemId,
  formMessageId,
} = useFormField();
</script>

<template>
  <div class="relative mt-2 max-w-md sm:col-span-2 sm:mt-0">
    <Slot
      :id="formItemId"
      :aria-describedby="!error ? `${formDescriptionId}` : `${formDescriptionId} ${formMessageId}`"
      :aria-invalid="!!error"
    >
      <slot />
    </Slot>
  </div>
</template>

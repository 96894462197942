<script setup lang="ts">
import type { DefaultLayoutQuery } from '#gql';

const { isBurgerNavigationVisible } = useBurgerNavigation();

defineProps<{
  headerData: DefaultLayoutQuery['header'];
  mainNavigationItems: DefaultLayoutQuery['mainNavigation']['items'];
}>();
</script>

<template>
  <div
    class="burger-menu fixed inset-y-0 right-0 z-[60] flex w-full select-none overflow-y-auto shadow-sm duration-300"
    :class="[isBurgerNavigationVisible ? 'translate-x-0' : 'translate-x-full']"
  >
    <LazyBurgerMenuComponent
      :header-data="headerData"
      :main-navigation-items="mainNavigationItems"
    />
  </div>
</template>

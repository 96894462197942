<script setup lang="ts">
import { useRouteQuery } from '@vueuse/router';
import {
  SearchIcon,
  XIcon,
} from 'lucide-vue-next';
import {
  PRODUCT_TYPE_PARAM,
  SEARCH_PARAM,
} from '@/config/filter';
import { productTypesRecognition } from '@/config/product-types';

withDefaults(defineProps<{ isDesktopHeader: boolean }>(), { isDesktopHeader: true, })

type ProductType = {
  product: string;
  variations: string;
};

const route = useRoute();
const { locale } = useI18n();
const localePath = useLocalePath();

const {
  getSearchParams,
  setSearchParams,
  toggleActiveFilters,
} = useActiveFilters();

const productTypes = productTypesRecognition;
type LocaleKeys = keyof typeof productTypes;

const routeQuerySearchTerm = useRouteQuery<string>(SEARCH_PARAM);
const routeQueryProductType = useRouteQuery<string>(PRODUCT_TYPE_PARAM);

const searchQuery = ref(routeQuerySearchTerm.value || '');
const selectedProductType = ref(routeQueryProductType.value || '');

const inputElementSelector = `input[name="${__props.isDesktopHeader
  ? 'desktop'
  : 'mobile'}SearchInput"]`;

watch(
  () => routeQuerySearchTerm.value,
  (newSearchTerm) => {
    if (typeof newSearchTerm === 'undefined' && getSearchParams.value?.query !== null) {
      searchQuery.value = getSearchParams.value.query
        ? getSearchParams.value.query
        : '';
    } else if (!newSearchTerm) {
      searchQuery.value = '';
    } else {
      searchQuery.value = newSearchTerm;
    }
  },
);

// if on home, remove selected product and search term
watch(
  () => route.name,
  () => {
    const isPageNotSearchContext = [
      'search',
      'product',
    ].some((entry) => route.name.includes(entry));
    if (!isPageNotSearchContext) {
      selectedProductType.value = '';
      searchQuery.value = '';

      setSearchParams({
        productType: null,
        query: null,
      });
    }
  },
);

watch(
  () => routeQueryProductType.value,
  (newProductType) => {
    if (typeof newProductType === 'undefined' && getSearchParams?.value?.productType !== null) {
      selectedProductType.value = getSearchParams.value.productType
        ? getSearchParams.value.productType
        : '';
    } else if (!newProductType) {
      selectedProductType.value = '';
    } else {
      selectedProductType.value = newProductType;
    }
  },
);

const findProductMatches = (types: ProductType[]) => {
  if (searchQuery.value) {
    const words = types.flatMap((item: any) => item.variations.split(',').filter((variation: string) => variation.trim() !== ''));
    const regexPattern = new RegExp(`\\b(${words.join('|')})\\b`, 'gi');
    const productMatches = searchQuery.value.match(regexPattern);
    searchQuery.value = searchQuery.value.replace(regexPattern, '').trim()
      .replace(/\s+/g, ' ');
    if (productMatches) {
      const productTypeMatch = types.find((type) => type.variations.toLocaleLowerCase().split(',')
        .includes(productMatches[0].toLocaleLowerCase()));
      if (productTypeMatch) {
        return productTypeMatch.product;
      }
    }
  }
  return null;
};

const focusInput = () => {
  const inputElement = document.querySelector(inputElementSelector) as HTMLInputElement;
  if (inputElement) {
    inputElement.focus();
  }
};

const blurInput = () => {
  const inputElement = document.querySelector(inputElementSelector) as HTMLInputElement;
  if (inputElement) {
    inputElement.blur();
  }
};

const updateSelectedProduct = (product: string) => {
  selectedProductType.value = product;
  focusInput();
};

const navigateToResultPage = async () => {
  if (searchQuery.value !== '') {
    if (locale.value in productTypes) {
      let match = findProductMatches(productTypes[locale.value as LocaleKeys]);
      if (!match && locale.value !== 'uk') {
        match = findProductMatches(productTypes.uk);
      }
      if (match) {
        selectedProductType.value = match;
      }
    }

    const queryObject: { [PRODUCT_TYPE_PARAM]?: string; [SEARCH_PARAM]?: string } = {};
    if (searchQuery.value) {
      queryObject[SEARCH_PARAM] = searchQuery.value as string;
    }

    if (selectedProductType.value) {
      queryObject[PRODUCT_TYPE_PARAM] = selectedProductType.value as string;
    }

    if (route.name.includes('search')) {
      if (getSearchParams?.value?.productType && getSearchParams.value?.productType !== selectedProductType.value) {
        toggleActiveFilters({
          filterCategoryName: 'product',
          filterKey: getSearchParams.value.productType,
          wasFilterSelected: true,
        });
      }

      if (selectedProductType.value && getSearchParams?.value?.productType !== selectedProductType.value) {
        toggleActiveFilters({
          filterCategoryName: 'product',
          filterKey: selectedProductType.value,
          wasFilterSelected: false,
        });
      }
    }

    setSearchParams({
      productType: selectedProductType.value
        ? selectedProductType.value
        : null,
      query: searchQuery.value
        ? searchQuery.value
        : null,
    });

    await navigateTo({
      path: localePath('/search'),
      query: queryObject,
    });
  }
};
</script>

<template>
  <div class="search-box relative flex h-11 w-full items-center overflow-hidden rounded-3xl border-[1.5px] border-dark bg-white pr-3 outline-none transition-all duration-150 placeholder:text-sm placeholder:text-dark focus-within:border-dark focus-within:shadow-lg focus-within:ring-1 focus-within:ring-dark focus-visible:border-dark focus-visible:ring-dark md:pr-0">
    <ProductDropdown @update:selected-product="updateSelectedProduct" />
    <input
      :ref="`${isDesktopHeader ? 'desktop' : 'mobile'}SearchInput`"
      v-model="searchQuery"
      autocapitalize="off"
      autocomplete="off"
      autocorrect="off"
      class="focus-visible:none order-3 w-full min-w-[50%] grow overflow-hidden border-none p-0 pl-3 text-sm focus:ring-0 md:pr-11"
      enterkeyhint="search"
      spellcheck="false"
      type="search"
      :name="`${isDesktopHeader ? 'desktop' : 'mobile'}SearchInput`"
      :placeholder="$t('common.searchPlaceholder')"
      @keyup.enter="navigateToResultPage(); blurInput()"
    />
    <XIcon
      v-show="searchQuery.length"
      class="absolute right-2 order-3 mr-2 flex size-6 cursor-pointer items-center pl-1 text-grey-semidark opacity-50 transition-opacity duration-150 hover:opacity-100 md:relative md:right-10"
      @click="searchQuery = ''; focusInput()"
    />
    <button
      class="absolute right-0 order-4 mr-1 hidden size-9 min-w-9 cursor-pointer items-center justify-center rounded-full bg-white transition-all duration-300 ease-in-out hover:bg-grey-light md:flex"
      @click="navigateToResultPage()"
    >
      <SearchIcon class="inset-y-0 flex size-5 text-dark" />
    </button>
  </div>
</template>
